<template>
  <div>
    <!--overview-->
    <b-card-actions
      v-if="surveyInstance && surveyTemplate"
      :title="surveyTemplate.title"
      action-collapse
    >
      <b-card-text>
        <span class="font-weight-bolder">发起：</span>
        {{ surveyInstance.ownerName }}
      </b-card-text>

      <b-card-text>
        <span class="font-weight-bolder">简介：</span>
        {{ surveyInstance.summary }}
      </b-card-text>

      <b-card-text>
        <span class="font-weight-bolder">填表人：</span>
        <span
          v-for="(optr, optrIdx) in surveyResults.operators"
          :key="'optr' + optrIdx"
        >
          {{ optr.name }} ({{ optr.indexList.join(",") }})项, 共{{
            optr.indexList.length
          }}项;
        </span>
      </b-card-text>

      <b-card-text>
        <span class="font-weight-bolder">负责业务/工作流程：</span>
        <span>{{ surveyResults.systems.join(", ") }}</span>
      </b-card-text>

      <b-card-text>
        <span class="font-weight-bolder">业务流程/系统功能简介：</span>
        <span>{{ surveyResults.systemDescriptions.join(", ") }}</span>
      </b-card-text>

      <b-card-text>
        <span class="font-weight-bolder">是否涉及个人信息：</span>
        <span>{{
          surveyResults.personalInformationInvolved ? "是" : "否"
        }}</span>
        <span v-if="surveyResults.personalInformationInvolved"
          >: {{ surveyResults.involvedPersonalInformation.join(", ") }}</span
        >
      </b-card-text>
    </b-card-actions>

    <!--results-->
    <b-card-actions
      v-if="surveyInstance && surveyTemplate"
      ref="resultsCard"
      title="提交记录"
      action-collapse
      action-refresh
      @refresh="loadResults(true)"
    >
      <b-table :fields="resultFields" :items="surveyResults.list">
        <template #cell(action)="data">
          <b-button
            variant="flat-primary"
            size="sm"
            @click="showResult(data.item)"
          >
            编辑
          </b-button>
          <b-button
            variant="flat-danger"
            size="sm"
            @click="deleteResult(data.item)"
          >
            删除
          </b-button>
        </template>
      </b-table>
      <b-col class="text-right mt-1">
        <b-button variant="primary" size="sm" @click="newResult()">
          新增记录
        </b-button>
      </b-col>
    </b-card-actions>

    <b-card>
      <template #header>
        <b-col><span class="card-title ">问题列表</span></b-col>
        <b-col v-if="resultPageStart >= 0" class="text-right">
          当前{{ resultPageStart + 1 }}-{{ resultPageEnd + 1 }}
          /总数{{surveyTemplate.questions.length}}</b-col
        >
      </template>

      <b-row v-if="surveyResult">
        <template v-for="(question, qIdx) in resultQuestions">
          <b-col md="6" sm="12" class="pr-1" :key="qIdx">
            <b-form-group
              v-if="question.visible"
              :label="`${(resultPage - 1) * resultPageSize + qIdx + 1}. ${
                question.question
              }`"
            >
              <!--- 文本 -->
              <b-form-input
                v-if="question.answerType == '文本'"
                v-model="surveyResult.answers[qIdx].answer"
              />
              <!-- 列表 -->
              <!-- <vue-autosuggest
          v-else-if="question.answerType == '列表'"
          v-model="surveyResult.answers[qIdx].answer"
          :suggestions="surveyFields"
          :inputProps="inputProps"
        /> -->
              <v-select
                v-else-if="question.answerType == '列表'"
                v-model="surveyResult.answers[qIdx].answer"
                placeholder="键入关键字以快速搜索"
                :options="surveyFields"
                :get-option-label="
                  (option) => option.fieldNameC + ' ' + option.fieldNameE
                "
                @option:selected="
                  (selectedOption) => onSelect(qIdx, selectedOption)
                "
              >
                <template
                  #option="{ fieldNameC, fieldNameE, ccpaLevel0, ccpaLevel1 }"
                >
                  <span>{{ fieldNameC }}</span> <span>{{ fieldNameE }}</span>
                  <b-badge variant="light-primary" class="mx-1" size="sm">
                    {{ ccpaLevel0 }}
                  </b-badge>
                  <b-badge variant="light-primary" class="mr-1" size="sm">
                    {{ ccpaLevel1 }}
                  </b-badge>
                </template>
              </v-select>

              <!-- 字段清单 -->
              <b-form-radio-group
                v-else-if="question.answerType == '字段清单'"
                v-model="surveyResult.answers[qIdx].answer"
                :options="yesNoOptions"
              />
              <!-- 是否 -->
              <b-form-radio-group
                v-else-if="question.answerType == '是否'"
                v-model="surveyResult.answers[qIdx].answer"
                :options="yesNoOptions"
              />
              <!-- 是否控制列 -->
              <b-form-radio-group
                v-else-if="question.answerType == '是否控制列'"
                v-model="surveyResult.answers[qIdx].answer"
                @change="(selected) => onCtlChange(qIdx, selected)"
                :options="yesNoOptions"
              />
            </b-form-group>
          </b-col>
        </template>

        <b-col cols="6" class="text-left">
          <b-button
            v-if="!isFirstPage"
            variant="primary"
            @click="changeResultPagePrev"
            >上一页</b-button
          >
        </b-col>

        <b-col cols="6" class="text-right">
          <b-button
            v-if="!isLastPage"
            variant="primary"
            @click="changeResultPageNext"
            >下一页</b-button
          >
          <b-button v-else variant="primary" @click="onSave">提交</b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardFooter,
  BCardText,
  BMedia,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BPagination,
  BBadge,
  BIconShieldLock,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
  BTable,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { VueAutosuggest } from "vue-autosuggest";
import vSelect from "vue-select";

import { mapState, mapActions } from "vuex";

export default {
  name: "DataMapForm",
  components: {
    BCard,
    BCardHeader,
    BCardFooter,
    BCardText,
    BMedia,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BPagination,
    BBadge,
    BIconShieldLock,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRadio,
    BFormRadioGroup,
    BTable,
    BCardActions,
    VueAutosuggest,
    vSelect,
  },

  data() {
    return {
      eid: 0,
      surveyInstanceId: 0,
      surveyInstance: null,
      surveyTemplate: null,
      surveyCatalogs: [],
      surveyFields: [],
      surveyResult: null,

      // 当前用户在该调研中的身份信息
      orgId: 0,
      isManager: false,

      //yes or no options
      yesNoOptions: [
        { text: "是", value: true },
        { text: "否", value: false },
      ],

      // 问题列表分类
      rootCatalogs: new Set(),

      // 问题列表分页
      resultPage: 1,
      resultPageSize: 10,
      resultPageStart: -1,
      resultPageEnd: 9,
      //resultQuestions:[],
      isLastPage: false,
      isFirstPage: true,

      // survey Results (saved)
      surveyResults: {
        operators: [],
        orgs: [],
        systems: [],
        systemDescriptions: [],
        personalInformationInvolved: false,
        involvedPersonalInformation: [],
        list: [],
      },
      surveyResultTotal: 0,
      surveyResultPage: 1,
      surveyResultPageSize: 10,
      resultFields: [
        { key: "operatorName", label: "填表人" },
        { key: "answers[0].answer", label: "系统" },
        { key: "answers[6].answer.fieldNameC", label: "字段(中文)" },
        { key: "answers[6].answer.fieldNameE", label: "Field(English)" },
        { key: "createdAt", label: "填表时间" },
        { key: "updatedAt", label: "更新时间" },
        { key: "action", label: "操作", thStyle: { width: "200px" } },
      ],

      // 列表 autoselection 配置
      inputProps: {
        id: "autosuggest__input",
        class: "form-control",
        placeholder: "",
      },
    };
  },

  computed: {
    // 当前编辑的问题列表
    resultQuestions() {
      let length = this.surveyTemplate.questions.length;
      this.resultPageStart = (this.resultPage - 1) * this.resultPageSize;
      this.resultPageEnd = this.resultPageStart + this.resultPageSize;
      this.isLastPage = this.resultPageEnd >= length;
      this.isFirstPage = this.resultPage === 1;

      return this.surveyTemplate.questions.slice(
        this.resultPageStart,
        this.resultPageEnd
      );
    },

    ...mapState({
      user: (state) => state.appOptions.user,
    }),
  },

  beforeMount() {
    this.loadUser();
    this.loadEid();
  },

  created() {
    const routeData = this.$router.resolve({
      name: "datamap-form",
      params: {
        eid: 100,
        surveyInstanceId: 237,
      },
    });
    // console.log(routeData.href);

    this.loadData();
  },

  methods: {
    changeResultPageNext() {
      // debugger
      this.resultPage++;
    },
    changeResultPagePrev() {
      this.resultPage--;
    },

    getNewSurveyResult(eid, instanceId, optrId, optrName) {
      let result = {
        eid: eid,
        surveyInstanceId: instanceId,
        operatorName: optrName,
        operatorId: optrId,
        status: 1,
        answers: [],
      };
      this.surveyTemplate.questions.forEach((question) => {
        result.answers.push({
          questionId: question.id,
          answer: null,
        });
      });

      return result;
    },

    fillResultAnswers(result) {
      // prepare survey results
      result.eid = this.eid;
      result.surveyInstanceId = this.surveyInstanceId;
      result.operatorName = this.user.nickName;
      result.operatorId = this.user.id;
      questions.forEach((question) => {
        result.answers.push({
          qId: question.id,
          answer: null,
        });
      });
    },

    // load saved survey results
    loadResults(showLoading) {
      if (showLoading) this.$refs["resultsCard"].showLoading = true;

      // 部门负责人可以查看部门所有的结果
      // 否则，只能查看自己的结果
      let params = { params: { surveyInstanceId: this.surveyInstanceId } };
      if (this.isManager) {
        params.params.orgId = this.orgId;
      } else {
        params.params.operatorId = this.user.id;
      }

      this.$http.get("/datamapresults", params).then((response) => {
        this.surveyResults.list = response.data.data.list;
        this.surveyResults.list.forEach((result, index) => {
          let hIndex = index + 1;
          // convert string to json
          result.answers = result.answers ? JSON.parse(result.answers) : [];

          // summarize operator
          let optrName = result.operatorName;
          let operator = this.surveyResults.operators.find(
            (optr) => optr.id == result.operatorId
          );
          if (!operator) {
            operator = {
              id: result.operatorId,
              name: optrName,
              indexList: [hIndex],
            };
            this.surveyResults.operators.push(operator);
          } else {
            operator.indexList.push(hIndex);
          }

          // summarize org

          // summarize system
          let system = result.answers[0].answer;
          if (system && this.surveyResults.systems.indexOf(system) == -1)
            this.surveyResults.systems.push(system);

          // summarize system description
          let systemDescription = result.answers[1].answer;
          if (
            systemDescription &&
            this.surveyResults.systemDescriptions.indexOf(systemDescription) ==
              -1
          ) {
            this.surveyResults.systemDescriptions.push(systemDescription);
          }

          // summarize personal information
          let personalInformationInvolved =
            result.answers[8].answer || result.answers[9].answer;
          if (personalInformationInvolved) {
            this.surveyResults.personalInformationInvolved = true;
            let personalInformation = result.answers[6].answer;
            if (
              personalInformation &&
              this.surveyResults.involvedPersonalInformation.indexOf(
                personalInformation
              ) == -1
            ) {
              this.surveyResults.involvedPersonalInformation.push(
                personalInformation.fieldNameC
              );
            }
          }

          // -- get system qustion index from the template
          //   debugger
          //   let systemQuestion = this.surveyTemplate.questions.find(
          //     (question) => question.question == "系统/APK名称"
          //   );
          //   let systemIndex = systemQuestion ? systemQuestion.index : -1;
          //   // -- get system name from the answer
          //   if (systemIndex >= 0) {
          //     let systemName = result.answers[systemIndex]
          //       ? result.answers[systemIndex].answer
          //       : "";
          //     if (systemName) this.surveyResults.systems.push(systemName);
          //   }
        });

        this.surveyResultTotal = response.data.data.total;
      });
      if (showLoading) this.$refs["resultsCard"].showLoading = false;
    },

    loadData() {
      this.eid = this.$route.params.eid * 1;
      this.surveyInstanceId = this.$route.params.surveyInstanceId * 1;

      // load fields
      this.$http.get("/datamapfields").then((response) => {
        this.surveyFields = response.data.data.list;
      });

      // load particiant information
      this.$http
        .get("/datamapparticipants", { params: { operatorId: this.user.id } })
        .then((response) => {
          let participant =
            response.data.data.list.length > 0
              ? response.data.data.list[0]
              : null;
          if (participant) {
            // console.log(participant);
            this.orgId = participant.orgId;
            this.isManager = participant.isManager;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      // load survey instance
      this.$http.get("/datamaps/" + this.surveyInstanceId).then((response) => {
        this.surveyInstance = response.data.data;

        // load survey template(with questions)
        this.$http
          .get("/libsurveyswithq/" + this.surveyInstance.templateId)
          .then((response) => {
            // sort questions by index(order)
            let questions = response.data.data.questions;
            questions.sort((a, b) => {
              return a.index - b.index;
            });

            // get survey catalogs
            let curCatalog = null;
            questions.forEach((question) => {
              if (question.catalog) {
                if (curCatalog == null || curCatalog != question.catalog) {
                  curCatalog = {
                    id: question.catalogId,
                    name: question.catalogName,
                    questions: [],
                  };
                  this.surveyCatalogs.push(curCatalog);
                }
                curCatalog.questions.push(question);
              }
            });

            // set question visibility
            questions.forEach((question) => {
              if (question.answerType == "是否控制列") {
                this.$set(question, "visible", true);
                let IDs = question.list.split("##");
                let toCtlList = questions.filter((q) =>
                  IDs.includes(q.index.toString())
                );
                toCtlList.forEach((q) => {
                  this.$set(q, "visible", false);
                });
                // console.log(toCtlList, IDs);
              } else {
                if (!question.hasOwnProperty("visible")) {
                  this.$set(question, "visible", true);
                }
              }
            });

            // store survey result
            this.surveyTemplate = response.data.data;

            // load results
            this.loadResults();
          });
      });
    },

    showResult(item) {
      this.surveyResult = item;
    },

    deleteResult(item) {
      this.$http.delete("/datamapresults/" + item.id).then((response) => {
        this.$toast.success("删除成功");
        this.loadResults();
        this.surveyResult = null;
      });
    },

    newResult() {
      //   debugger;
      this.surveyResult = this.getNewSurveyResult(
        this.eid,
        this.surveyInstanceId,
        this.user.id,
        this.user.nickName
      );
    },

    // field selected
    onSelect(qIdx, selectedOption) {
      // this.surveyResult.answers[qIdx].answer = selectedOption.id;

      this.surveyResult.answers[qIdx + 2].answer =
        selectedOption.piplLevel1 == "一般个人信息";
      this.surveyResult.answers[qIdx + 3].answer =
        selectedOption.piplLevel1 == "敏感个人信息";
    },

    // ctl field changed
    onCtlChange(qIdx, selected) {
      //   console.log(qIdx, selected);
      let question = this.surveyTemplate.questions[qIdx];
      let IDs = question.list.split("##");
      let toCtlList = this.surveyTemplate.questions.filter((q) =>
        IDs.includes(q.index.toString())
      );
      toCtlList.forEach((q) => {
        q.visible = selected;
      });
    },

    // save survey result
    onSave() {
      //   debugger;
      let params = JSON.parse(JSON.stringify(this.surveyResult));
      params.answers = JSON.stringify(params.answers);

      if (params.id) {
        this.$http
          .put("/datamapresults/" + params.id, params)
          .then((response) => {
            this.$toast.success("保存成功");
            this.loadResults();
            this.surveyResult = null;
          });
      } else {
        this.$http.post("/datamapresults", [params]).then((response) => {
          this.$toast.success("保存成功");
          this.loadResults();
          this.surveyResult = null;
          //   this.$router.push(
          //     `/${this.eid}/datamap-file/${this.surveyInstanceId}`
          //   );
        });
      }
    },

    onReturn() {
      this.$router.push("/datamaps/" + this.surveyInstanceId);
    },

    ...mapActions({
      loadUser: "appOptions/LOAD_USER",
      loadEid: "appOptions/LOAD_EID",
    }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>